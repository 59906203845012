<template>
    <div class="pwd_container">
        <div class="pwd_box">
            <!-- 头像区域 -->
            <div class="avatar_box">
                <img src="../../assets/logo_1024.png" alt/>
            </div>
            <!-- 登录表单区域 -->
            <div class="pwd_alert">
                修改密码
            </div>
            <el-form
                    :model="form"
                    ref="changeFormRef"
                    label-width="80px"
                    class="pwd_form"
                    :rules="rules"
            >
                <el-form-item label="原密码" prop="oldPassword" >
                    <el-input type="password" :show-password="true" v-model="form.oldPassword" autocomplete="off" :clearable="true"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPassword" >
                    <el-input type="password" :show-password="true" v-model="form.newPassword" autocomplete="off" :clearable="true"></el-input>
                   <span style="font-size: 10px;"><i class="el-icon-warning" style=" color: #e6a23c;"></i> 8-20位，由大写字母、小写字母、数字和英文字符(除空格外)至少三种组成</span>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmPassword" >
                  <el-input type="password" :show-password="true" v-model="form.confirmPassword" autocomplete="off" :clearable="true"></el-input>
                </el-form-item>
                <el-form-item label="" class="pwd__btns-wapper" label-width="0">
                    <el-button class="pwd__btn"  @click="$router.push('/login')">返回登录</el-button>
                    <el-button class="pwd__btn" type="primary" @click="submitForm('form')">确认修改</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { changeMyPassword } from "@/api/login.js";

export default {
    data() {
        return {
            form: {
                oldPassword: "",
                newPassword: "",
                confirmPassword: ""
            },
            rules: {
                oldPassword: [{ required: true, message: '必填', trigger: 'blur' }],
                newPassword: [{ required: true, message: '必填', trigger: 'blur' }],
                confirmPassword: [{ required: true, message: '必填', trigger: 'blur' }],
            },
        };
    },
    created() {
      
    },
    methods: {
        submitForm() {
            this.$refs.changeFormRef.validate(async (valid) => {
                if (!valid) return;
                if(this.form.newPassword!=this.form.confirmPassword) {
                    this.$message.error('确认密码与新密码不一致');
                    return;
                }
                // this.form.id = this.$store.state.user.id
                this.form.is_admin = this.$store.state.user.type
                let params={
                    // menuId: 34,
                    menuCode:'change_my_password',
                    data:this.form
                }
                
                try {
                    let res = await changeMyPassword(params);
                    if(res.code===0){
                        this.$message({
                            type: 'success',
                            message: '密码修改成功!'
                        });
                        this.$router.push("/login")
                    }else{
                        this.$message({
                            type: 'error',
                            message: `${res.message||'操作失败!'}`
                        });
                    }
                } catch (error) {
                    this.$message({
                            type: 'error',
                            message: `${error||'操作失败!'}`
                        });
                }
                
            });
        },

    },
};
</script>

<style lang="scss" scoped>
.pwd_container {
    background-color: $color-bg-gray;
    height: 100%;
}

.pwd_box {
    width: 580px;
    //height: 340px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 10px #ddd;
    .avatar_box {
        height: 90px;
        width: 90px;
        border: 1px solid #eee;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0 0 10px #ddd;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #eee;
        }
    }
}

.pwd_alert {
    margin: 70px 60px 10px 60px;
    box-sizing: border-box;
    font-weight: 800;
    font-size: 18px;
}
.pwd_form {
    width: 100%;
    padding: 10px 60px;
    box-sizing: border-box;
}

.el-form {
    display: block;
}

.pwd__btns-wapper {
    display: flex;
    justify-content: space-around;
    margin: 25px 0;
    width: 100%;
    .pwd__btn {
        width: 100px;
    }
}

.reset_a {
    color: $color-a;
    position: fixed;
    right: 60px;
    font-size: $font-small;
}

.email_verify_btn {
    background: $color-theme-normal !important;
    color: #ffffff !important;
    border: 1px solid $color-theme-normal !important;
    border-radius: 0 !important;
    font-size: $font-small !important;
}
</style>
